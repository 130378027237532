import localStore from "./localstore.util";

export const setUserInfo = async (info) => {
  const _data = { ...info };
  await localStore.store_data("UserInfo", _data);
  return true;
};

export const getUserInfo = () => localStore.get_data("UserInfo");
export const removeUserInfo = () => localStore.remove_data("UserInfo");
