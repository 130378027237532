import { BaseURL } from '../lib/api'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createSlice } from '@reduxjs/toolkit'

export const profileData = createSlice({
    name: 'profile',
    initialState: {
        isLoading: false,
        error: '',
        userData: {},
    },
    reducers: {
        login: (state) => {
            state.isLoggedIn = true
        },
        setUserData: (state, action) => {
            state.userData = action.payload
        },
    },
    extraReducers: (builder) => {
        // builder.addCase(logoutUser.fulfilled, (state) => {
        //     state.user = null;
        //     state.token = null;
        // });
    },
})

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BaseURL}`,
        prepareHeaders: (headers) => {
            const currentToken = JSON.parse(localStorage.getItem('token'))
            if (currentToken) {
                headers.set('Authorization', `Bearer ${currentToken}`)
            }
            headers.set('Content-Type', 'application/json')
            // headers.set('Accept', 'application/json');
            headers.set('ngrok-skip-browser-warning', true)
            return headers
        },
    }),
    tagTypes: ['onBoradingType'],
    endpoints: (builder) => ({
        masterDetails: builder.query({
            query: () => ({
                url: 'all-master-details',
                method: 'GET'
            }),
        }),
        profile: builder.mutation({
            query: ({ data }) => ({
                url: 'shop-details-store',
                method: 'POST',
                body: { ...data }
            }),
        }),
        profileAdd: builder.mutation({
            query: ({ data }) => ({
                url: 'shop-details-store',
                method: 'POST',
                body: { ...data }
            }),
        }),
        profileUpdate: builder.mutation({
            query: (data) => ({
                url: 'shop-details-update',
                method: 'POST',
                body: { ...data }
            }),
        }),
        AddOrUpdateBankBetails: builder.mutation({
            query: (data) => ({
                url: 'add-or-update-bank-details',
                method: 'POST',
                body: { ...data }
            }),
        }),
        AddOrUpdateProductDetails: builder.mutation({
            query: (data) => ({
                url: 'add-or-update-product-details',
                method: 'POST',
                body: { ...data }
            }),
        }),
        AddOrUpdateHsnNo: builder.mutation({
            query: (data) => ({
                url: 'add-or-update-hsn-no',
                method: 'POST',
                body: { ...data }
            }),
        }),
        ShopDetailActivation:builder.mutation({
            query:(data)=>({
                url:'shop-detail-activation',
                method:'POST',
                body:{...data}
            }),
        }),
        BankDetailActivation:builder.mutation({
            query:(data)=>({
                url:'bank-detail-activation',
                method:'POST',
                body:{...data}
            }),
        }),
        CustomerDetailsStore:builder.mutation({
            query:(data)=>({
                url:'customer-details-store',
                method:'POST',
                body:{...data}
            }),
        }),
        CustomerDetailsUpdate:builder.mutation({
            query:(data)=>({
                url:'customer-details-update',
                method:'POST',
                body:{...data}
            }),
        }),
        InvoiceDetailStore:builder.mutation({
            query:(data)=>({
                url:'invoice-detail-store',
                method:'POST',
                body:{...data}
            }),
        }),
        InvoiceViewEdit:builder.mutation({
            query:(data)=>({
                url:'invoice-view-and-edit',
                method:'POST',
                body:{...data}
            }),
        }),
        invoiceDetailUpdate:builder.mutation({
            query:(data)=>({
                url:'invoice-detail-update',
                method:'POST',
                body:{...data}
            }),
        }),
        downloadInvoice:builder.query({
            query:({invoice_id,download_type})=>({
                url:`download-invoice?invoice_id=${invoice_id}&download_type=${download_type}`,
                method:'GET',
                responseHandler: (response) => response.blob()
            }),
        }),
    }),
})
export const { useMasterDetailsQuery,
     useProfileMutation, 
     useProfileAddMutation, 
     useProfileUpdateMutation,
     useAddOrUpdateBankBetailsMutation,
     useAddOrUpdateProductDetailsMutation,
     useAddOrUpdateHsnNoMutation,
     useShopDetailActivationMutation,
     useBankDetailActivationMutation,
     useCustomerDetailsStoreMutation,
     useCustomerDetailsUpdateMutation,
     useInvoiceDetailStoreMutation,
     useInvoiceViewEditMutation,
     useInvoiceDetailUpdateMutation,
     useDownloadInvoiceQuery
    } = profileApi