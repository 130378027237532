// api.jsx
import axios from 'axios';
import {API} from '../config/API/api.config'
export const BaseURL=API.endpoint+'/'
export const ApiPostNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${BaseURL}${type}`,
        userData,
      )
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};
