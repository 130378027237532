import React, { useState, useEffect } from 'react';
import './profile.css';
import { useMasterDetailsQuery, useProfileUpdateMutation, useAddOrUpdateBankBetailsMutation, useAddOrUpdateProductDetailsMutation, useAddOrUpdateHsnNoMutation, useShopDetailActivationMutation, useBankDetailActivationMutation } from '../../slices/Profile';
const Profile = () => {
  const { data: masterData, refetch: masterDataRefetch } = useMasterDetailsQuery();
  const [profileUpdate] = useProfileUpdateMutation();
  const [bankAddUpdate] = useAddOrUpdateBankBetailsMutation();
  const [profileAddUpdate] = useAddOrUpdateProductDetailsMutation();
  const [hsnAddUpdate] = useAddOrUpdateHsnNoMutation();
  const [shopDetailActivation] = useShopDetailActivationMutation()
  const [BankDetailActivation] = useBankDetailActivationMutation()
  const [profileData, setProfileData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [isEditingIndex, setIsEditingIndex] = useState(null);
  const [products, setProducts] = useState([]);
  const [hsnCodes, setHsnCodes] = useState([]);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [newProduct, setNewProduct] = useState('');
  const [isAddingHsn, setIsAddingHsn] = useState(false);
  const [newHsn, setNewHsn] = useState('');
  useEffect(() => {
    if (masterData) {
      setProfileData(masterData.data.shop_details_data || []);
      setBankData(masterData.data.bank_details_data || []);
      setProducts(masterData?.data?.products_data || [])
      setHsnCodes(masterData.data.hsn_data || [])
    }
  }, [masterData]);

  const combinedData = [];
  const maxLength = Math.max(profileData.length, bankData.length);

  for (let i = 0; i < maxLength; i++) {
    if (profileData[i]) {
      combinedData.push({ type: 'profile', data: profileData[i], index: i });
    }
    if (bankData[i]) {
      combinedData.push({ type: 'bank', data: bankData[i], index: i });
    }
  }

  const handleEditClick = (type, index, item) => {

    setIsEditingIndex({ type, index });
  };

  const handleSaveProfileClick = async (item) => {
    const payload = {
      shop_id: item?.data?.id,
      shop_name: item?.data?.shop_name,
      address: item?.data?.address,
      gst_no: item?.data?.gst_no,
      state_code: item?.data?.state_code,
      phone_no: item?.data?.phone_no
    };

    try {
      await profileUpdate(payload);
      masterDataRefetch();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
    setIsEditingIndex(null);
  };

  const handleSaveBankClick = async (item) => {
    const payload = {
      bank_id: item?.data?.id,
      bank_name: item?.data?.bank_name,
      branch_name: item?.data?.branch_name,
      bank_acc_no: item?.data?.bank_acc_no,
      ifsc_code: item?.data?.ifsc_code,
      current_action: 'update'
    };
    try {
      await bankAddUpdate(payload);
      console.log('Updated Bank Data:', payload);
      masterDataRefetch();
    } catch (error) {
      console.error('Error updating bank:', error);
    }
    setIsEditingIndex(null);
  };
  const activeShop = async (item) => {
    const payload = {
      shop_id: item?.data?.id
    }
    try {
      let res = await shopDetailActivation(payload)
      masterDataRefetch();
    } catch (error) {
      console.error('Error updating bank:', error);
    }
  }
  const activeBank = async (item) => {
    const payload = {
      bank_id: item?.data?.id
    }
    try {
      let res = await BankDetailActivation(payload)
      masterDataRefetch();
    } catch (error) {
      console.error('Error updating bank:', error);
    }
  }
  const handleChange = (e, type, index) => {
    const { name, value } = e.target;
    if (type === 'profile') {
      const updatedProfileData = [...profileData];
      updatedProfileData[index] = {
        ...updatedProfileData[index],
        [name]: value
      };
      setProfileData(updatedProfileData);
    } else if (type === 'bank') {
      const updatedBankData = [...bankData];
      updatedBankData[index] = {
        ...updatedBankData[index],
        [name]: value
      };
      setBankData(updatedBankData);
    }
  };

  const [editIndex, setEditIndex] = useState(null);
  const [editedProduct, setEditedProduct] = useState('');
  const [editedHsn, setEditedHsn] = useState('');
  const handleEditClicks = (index, type) => {
    setEditIndex({ index, type });
    if (type === 'product') {
      setEditedProduct(products[index].product_name);
    } else {
      setEditedHsn(hsnCodes[index].hsn_no);
    }
  };

  const handleSave = async () => {
    if (editIndex) {
      const { index, type } = editIndex;

      if (type === 'product') {
        const updatedProducts = [...products];
        const productId = updatedProducts[index].product_id;
        updatedProducts[index] = { id: productId, product_name: editedProduct };
        setProducts(updatedProducts);
        console.log(`Updated Product ID: ${productId}, New Product Name: ${editedProduct}`);
        const payload = {
          product_name: editedProduct,
          pro_id: productId,
          current_action: 'update'
        }
        const res = await profileAddUpdate(payload)
      } else if (type === 'hsn') {
        const updatedHsnCodes = [...hsnCodes];
        const hsnId = updatedHsnCodes[index].id;
        updatedHsnCodes[index] = { id: hsnId, hsn_no: editedHsn };
        setHsnCodes(updatedHsnCodes);
        console.log(`Updated HSN ID: ${hsnId}, New HSN No: ${editedHsn}`);
        const payload = {
          hsn_no: editedHsn,
          hsn_id: hsnId,
          current_action: 'update'
        }
        const res = await hsnAddUpdate(payload)
      }
      setEditIndex(null);
      setEditedProduct('');
      setEditedHsn('');
      masterDataRefetch()

    }
  };

  const handleCancel = () => {
    setEditIndex(null);
    setEditedProduct('');
    setEditedHsn('');
  };
  const handleAddProduct = () => {
    setIsAddingProduct(true);
  };

  const handleProductChange = (e) => {
    setNewProduct(e.target.value);
  };

  const handleProductSubmit = async () => {
    if (newProduct) {
      const payload = {
        product_name: newProduct,
        current_action: 'add'
      }
      const res = await profileAddUpdate(payload)
      setIsAddingProduct(false);  // Optionally hide the input after submission
      setNewProduct('');
      masterDataRefetch()
    } else {
      console.log('Product name cannot be empty');
    }
  };
  const handleAddHsn = () => {
    setIsAddingHsn(true);
  };

  const handleHsnChange = (e) => {
    setNewHsn(e.target.value);
  };
  const handleHsnSubmit = async () => {
    if (newHsn) {
      const payload = {
        hsn_no: newHsn,
        current_action: 'add'
      }
      const res = await hsnAddUpdate(payload)
      setIsAddingHsn(false);
      setNewHsn('');
      masterDataRefetch()
    } else {
      console.log('HSN code cannot be empty');
    }
  };
  return (
    <div>
      <div className='customer_heading'>
        <h3>Profile Details</h3>
      </div>
      <div className="customer_card">
        <div>
          <div className="customer_card_data">
            <div>
              <div  className="customer_card_flex">
              <span className='customer_title'>Product Name: </span>
              <button onClick={handleAddProduct}>Add</button>
              </div>             

              {isAddingProduct && (
                <div>
                  <input
                    type="text"
                    value={newProduct}
                    onChange={handleProductChange}
                    placeholder="Enter product name"
                  />
                  <button onClick={handleProductSubmit}>Submit</button>
                </div>
              )}

              {products.map((product, index) => (
                <div key={index} >
                  {editIndex && editIndex.index === index && editIndex.type === 'product' ? (
                    <input
                      type="text"
                      value={editedProduct}
                      onChange={(e) => setEditedProduct(e.target.value)}
                    />
                  ) : (
                    <span className='address_details'>{product.product_name}</span>
                  )}
                  {editIndex && editIndex.index === index && editIndex.type === 'product' ? (
                    <div className="customer_card_flex">
                      <button onClick={() => handleSave(index)}>Save</button>
                      <button onClick={handleCancel}>Cancel</button>
                    </div>
                  ) : (
                    <button onClick={() => handleEditClicks(index, 'product')}>Edit</button>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="customer_card_data">
              <div>
              <div  className="customer_card_flex">
                <span className='customer_title'>HSN: </span>
                <button onClick={handleAddHsn}>Add</button>
                </div>
                {isAddingHsn && (
                  <div>
                    <input
                      type="text"
                      value={newHsn}
                      onChange={handleHsnChange}
                      placeholder="Enter HSN code"
                    />
                    <button onClick={handleHsnSubmit}>Submit</button>
                  </div>
                )}
                {hsnCodes.map((hsn, index) => (
                  <div key={index}>
                    {editIndex && editIndex.index === index && editIndex.type === 'hsn' ? (
                      <input
                        type="text"
                        value={editedHsn}
                        onChange={(e) => setEditedHsn(e.target.value)}
                      />
                    ) : (
                      <span className='address_details'>{hsn.hsn_no}</span>
                    )}
                    {editIndex && editIndex.index === index && editIndex.type === 'hsn' ? (
                      <div className="customer_card_flex">
                        <button onClick={() => handleSave(index)}>Save</button>
                        <button onClick={handleCancel}>Cancel</button>
                      </div>
                    ) : (
                      <button onClick={() => handleEditClicks(index, 'hsn')}>Edit</button>
                    )}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {combinedData.map((item, index) => (
          <div key={index} className="customer_card_data">
             <div  className="customer_card_flex">
            {item?.data?.status_id == 0 ?
              <button onClick={() => { item.type === 'profile' ? activeShop(item) : activeBank(item) }}>Active</button> : <span>Activated</span>}
            <button
              onClick={() =>
                isEditingIndex?.type === item.type && isEditingIndex?.index === item.index
                  ? item.type === 'profile'
                    ? handleSaveProfileClick(item)  // Pass the item data
                    : handleSaveBankClick(item)    // Pass the item data
                  : handleEditClick(item.type, item.index, item)
              }
            >
              {isEditingIndex?.type === item.type && isEditingIndex?.index === item.index ? 'Save' : 'Edit'}
            </button>
</div>
            <div>
              {item.type === 'profile' ? (
                <>
                  <div>
                    <span className='customer_title'>Name: </span>
                    {isEditingIndex?.type === 'profile' && isEditingIndex?.index === item.index ? (
                      <input
                        name='shop_name'
                        className='profile_data_input'
                        value={item.data.shop_name}
                        onChange={(e) => handleChange(e, 'profile', item.index)}
                      />
                    ) : (
                      <span className='address_details'>{item.data.shop_name}</span>
                    )}
                  </div>
                  <div>
                    <span className='customer_title'>Address: </span>
                    {isEditingIndex?.type === 'profile' && isEditingIndex?.index === item.index ? (
                      <textarea
                        name='address'
                        className='profile_data_input'
                        value={item.data.address}
                        onChange={(e) => handleChange(e, 'profile', item.index)}
                      />
                    ) : (
                      <span className='address_details'>{item.data.address}</span>
                    )}
                  </div>
                  <div>
                    <span className='customer_title'>Gstin: </span>
                    {isEditingIndex?.type === 'profile' && isEditingIndex?.index === item.index ? (
                      <input
                        name='gst_no'
                        className='profile_data_input'
                        value={item.data.gst_no}
                        onChange={(e) => handleChange(e, 'profile', item.index)}
                      />
                    ) : (
                      <span className='address_details'>{item.data.gst_no}</span>
                    )}
                  </div>
                  <div>
                    <span className='customer_title'>Phone Number: </span>
                    {isEditingIndex?.type === 'profile' && isEditingIndex?.index === item.index ? (
                      <input
                        name='phone_no'
                        className='profile_data_input'
                        value={item.data.phone_no}
                        onChange={(e) => handleChange(e, 'profile', item.index)}
                      />
                    ) : (
                      <span className='address_details'>{item.data.phone_no}</span>
                    )}
                  </div>
                  <div>
                    <span className='customer_title'>State code: </span>
                    {isEditingIndex?.type === 'profile' && isEditingIndex?.index === item.index ? (
                      <input
                        name='state_code'
                        className='profile_data_input'
                        value={item.data.state_code}
                        onChange={(e) => handleChange(e, 'profile', item.index)}
                      />
                    ) : (
                      <span className='address_details'>{item.data.state_code}</span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <span className='customer_title'>Bank Name: </span>
                    {isEditingIndex?.type === 'bank' && isEditingIndex?.index === item.index ? (
                      <input
                        name='bank_name'
                        className='profile_data_input'
                        value={item.data.bank_name}
                        onChange={(e) => handleChange(e, 'bank', item.index)}
                      />
                    ) : (
                      <span className='address_details'>{item.data.bank_name}</span>
                    )}
                  </div>
                  <div>
                    <span className='customer_title'>Branch: </span>
                    {isEditingIndex?.type === 'bank' && isEditingIndex?.index === item.index ? (
                      <input
                        name='branch_name'
                        className='profile_data_input'
                        value={item.data.branch_name}
                        onChange={(e) => handleChange(e, 'bank', item.index)}
                      />
                    ) : (
                      <span className='address_details'>{item.data.branch_name}</span>
                    )}
                  </div>
                  <div>
                    <span className='customer_title'>Bank A/c: </span>
                    {isEditingIndex?.type === 'bank' && isEditingIndex?.index === item.index ? (
                      <input
                        name='bank_acc_no'
                        className='profile_data_input'
                        value={item.data.bank_acc_no}
                        onChange={(e) => handleChange(e, 'bank', item.index)}
                      />
                    ) : (
                      <span className='address_details'>{item.data.bank_acc_no}</span>
                    )}
                  </div>
                  <div>
                    <span className='customer_title'>Ifsc Code: </span>
                    {isEditingIndex?.type === 'bank' && isEditingIndex?.index === item.index ? (
                      <input
                        name='ifsc_code'
                        className='profile_data_input'
                        value={item.data.ifsc_code}
                        onChange={(e) => handleChange(e, 'bank', item.index)}
                      />
                    ) : (
                      <span className='address_details'>{item.data.ifsc_code}</span>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Profile;
