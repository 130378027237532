import './App.css';
import Customer from './component/customer/Customer';
import { Invoice } from './component/invoice/Invoice';
import InvoiceBill from './component/invoicebill/InvoiceBill';
import Login from './component/login/Login';
import Profile from './component/Profile/Profile';
import SideMenu from './includes/sidemenu/SideMenu';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getUserInfo } from './utils/user.util';

function App() {
  const user = getUserInfo();
  console.log(user);

  return (
    <Router>
      <div className="app-container">
        <Routes>
          {/* Redirect to the login page if the user is not authenticated and tries to access a protected route */}
          <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
          
          {/* Protected routes */}
          <Route
            path="/"
            element={
              user ? (
                <>
                  <SideMenu />
                  <div className="content">
                    <Invoice />
                  </div>
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/customer"
            element={
              user ? (
                <>
                  <SideMenu />
                  <div className="content">
                    <Customer />
                  </div>
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/invoicebill"
            element={
              user ? (
                <>
                  <SideMenu />
                  <div className="content">
                    <InvoiceBill />
                  </div>
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/profile"
            element={
              user ? (
                <>
                  <SideMenu />
                  <div className="content">
                    <Profile />
                  </div>
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          
          {/* Catch-all route for unmatched URLs */}
          <Route path="*" element={<Navigate to={user ? "/" : "/login"} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
