import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { logoutT } from '../../utils/auth.util';
// import './SideMenu.css'; // Make sure to import your CSS file

const SideMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='slidebar'>
      <button className="toggle-button" onClick={toggleMenu}>
        {isOpen ? '1' : <span>-</span>}
      </button>
      {isOpen && (
        <div className="side-menu">
          <NavLink to="/">
            Invoice
          </NavLink>
          <NavLink to="/customer">
            Customer
          </NavLink>
          <NavLink to="/invoicebill">
            Invoice Bill 
          </NavLink>
          <NavLink to="/profile">
            Profile
          </NavLink>
          <button onClick={logoutT}>Logout</button>
        </div>
      )}
    </div>
  );
};

export default SideMenu;
