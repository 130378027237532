import React, { useEffect, useState } from 'react';
import './invoice.css';
import { toWords } from 'number-to-words';
import { useInvoiceDetailStoreMutation, useMasterDetailsQuery } from '../../slices/Profile';
import { InvoiceForm } from '../Modal/invoiceForm/InvoiceForm';

export const Invoice = () => {
   

    return (
       <InvoiceForm/>
    );
};
