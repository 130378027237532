const protocol = 'https';
const host = 'api.srisendhurtex.in/api/v1'

const port = ''
const trailUrl = ''

const hostUrl = `${protocol}://${host}${port ? ':' + port : ''}`
const endpoint = `${protocol}://${host}${port ? ':' + port : ''}${trailUrl}`

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
}