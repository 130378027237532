import React, { useState } from 'react';
import './customer.css';
import CustomerModal from '../Modal/customerModal/CustomerModal';
import { useMasterDetailsQuery } from '../../slices/Profile';

const Customer = () => {
  const { data: masterData, refetch: masterDataRefetch } = useMasterDetailsQuery();

  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState(null); // state to track customer being edited

  const handleAddCustomer = () => {
    setEditCustomerData(null); // reset the edit state
    setAddCustomerModal(true); // open modal
  };

  const handleEditCustomer = (customer) => {
    setEditCustomerData(customer); // set the customer data to edit
    setAddCustomerModal(true); // open modal
  };

  return (
    <div>
      <div className='customer_heading'>
        <h3>Customer Details</h3>
        <button onClick={handleAddCustomer}>Add Customer</button>
      </div>
      <div className="customer_card">
        {masterData && masterData?.data?.customers_data.map((value, index) => (
          <div key={index} className="customer_card_data">
            <button className='edit_button' onClick={() => handleEditCustomer(value)}>Edit</button>
            <div>
              <span className='customer_title'>Name: </span><span className='address_details'>{value?.cust_name}</span>
            </div>
            <div>
              <span className='customer_title'>Address: </span><span className='address_details'>{value?.cust_address}</span>
            </div>
            <div>
              <span className='customer_title'>Gstin: </span><span className='address_details'>{value?.gst_no}</span>
            </div>
          </div>
        ))}
      </div>
      {addCustomerModal && (
        <CustomerModal 
          setAddCustomerModal={setAddCustomerModal} 
          editCustomerData={editCustomerData} 
          refetchMasterData={masterDataRefetch} 
        />
      )}
    </div>
  );
};

export default Customer;
