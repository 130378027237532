import { setToken } from '../../utils/auth.util';
import { setUserInfo } from '../../utils/user.util';
import { ApiPostNoAuth } from '../api'; // Adjust import path as needed

export function userSingIn(info) {
    console.log(info, "infoinfo");

    return new Promise(async (resolve, reject) => {
        try {
            const res = await ApiPostNoAuth('login', info);
            setToken(res?.data?.data?.token)
            setUserInfo(res?.data?.data)
            resolve(res);
        } catch (error) {
            reject(error);
        }
    });
}
