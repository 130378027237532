import React, { useEffect, useState } from 'react';
import './InvoiceForm.css';
import { toWords } from 'number-to-words';
import { useInvoiceDetailStoreMutation, useInvoiceDetailUpdateMutation, useInvoiceViewEditMutation, useMasterDetailsQuery } from '../../../slices/Profile';

export const InvoiceForm = ({ invoiceId, invoiceType,setInvoice }) => {
    const { data: masterData, refetch: masterDataRefetch } = useMasterDetailsQuery();
    const [InvoiceDetailStore] = useInvoiceDetailStoreMutation();
    const [InvoiceViewEdit] = useInvoiceViewEditMutation();
    const [invoiceDetailUpdate] = useInvoiceDetailUpdateMutation();

    const formMode = (invoiceId ? invoiceType : 'new');

    const [formValues, setFormValues] = useState({
        invo_id: invoiceId || '',
        invoice_no: '',
        invoice_date: '',
        billed_to: masterData?.data?.customers_data[0]?.customer_id,
        shipped_to: masterData?.data?.customers_data[0]?.customer_id,
        product_id: masterData?.data?.products_data[0]?.product_id,
        hsn_id: masterData?.data?.hsn_data[0]?.id,
        initial_qty: '',
        three_percent: '',
        total_qty: '',
        rate: '',
        no_of_bags_from: '',
        no_of_bags_to: '',
        no_of_bags: '',
        total_amount: '',
        amount_before_tax: '',
        cgst_amount: 0,
        sgst_amount: 0,
        igst_amount: 0,
        tax_type: 'cgst_amountsgst_amount',
        invoice_amount: ''
    });
    console.log(formValues, "kk");
    useEffect(() => {
        if (masterData && masterData.data) {
            setFormValues(prevValues => ({
                ...prevValues,
                billed_to: masterData.data.customers_data[0]?.customer_id || prevValues.billed_to,
                shipped_to: masterData.data.customers_data[0]?.customer_id || prevValues.shipped_to,
                product_id: masterData.data.products_data[0]?.product_id || prevValues.product_id,
                hsn_id: masterData.data.hsn_data[0]?.id || prevValues.hsn_id
            }));
        }
    }, [masterData]);
    useEffect(() => {
        const fetchInvoiceData = async () => {
            if (invoiceId) {
                const payload = { invoice_id: invoiceId };
                try {
                    let res = await InvoiceViewEdit(payload);
                    if (res?.data?.data) {
                        setFormValues(prevValues => ({
                            ...prevValues,
                            ...res.data.data
                        }));
                    }
                } catch (error) {
                    console.error("Error fetching invoice data", error);
                }
            }else{
            }
        };

        fetchInvoiceData();
    }, [invoiceId, InvoiceViewEdit]);

    useEffect(() => {
        calculateValues();
    }, [formValues.initial_qty, formValues.rate]);

    useEffect(() => {
        calculateTax();
    }, [formValues.total_amount, formValues.tax_type]);

    useEffect(() => {
        calculateno_of_bags();
    }, [formValues.no_of_bags_from, formValues.no_of_bags_to]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const calculateValues = () => {
        const initialQty = parseFloat(formValues.initial_qty);
        const rate = parseFloat(formValues.rate);

        if (!isNaN(initialQty)) {
            const three_percent = initialQty * 0.03;
            const total_qty = initialQty - three_percent;

            let total_amount = '';
            if (!isNaN(rate)) {
                total_amount = (total_qty * rate).toFixed(2);
            }

            setFormValues(prevValues => ({
                ...prevValues,
                three_percent: three_percent.toFixed(2),
                total_qty: total_qty.toFixed(2),
                total_amount,
                amount_before_tax: total_amount
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                three_percent: '',
                total_qty: '',
                total_amount: '',
                amount_before_tax: ''
            }));
        }
    };

    const calculateTax = () => {
        const total_amount = parseFloat(formValues.total_amount);
        let cgst_amount = 0, sgst_amount = 0, igst_amount = 0, invoice_amount = '';

        if (!isNaN(total_amount) && total_amount !== 0) {
            if (formValues.tax_type === 'cgst_amountsgst_amount') {
                cgst_amount = (total_amount * 0.025).toFixed(2);
                sgst_amount = (total_amount * 0.025).toFixed(2);
                invoice_amount = (total_amount + parseFloat(cgst_amount) + parseFloat(sgst_amount)).toFixed(0);
            } else if (formValues.tax_type === 'igst_amount') {
                igst_amount = (total_amount * 0.05).toFixed(2);
                invoice_amount = (total_amount + parseFloat(igst_amount)).toFixed(0);
            }
        }

        setFormValues(prevValues => ({
            ...prevValues,
            cgst_amount,
            sgst_amount,
            igst_amount,
            invoice_amount
        }));
    };

    const calculateno_of_bags = () => {
        const no_of_bags_from = parseFloat(formValues.no_of_bags_from);
        const no_of_bags_to = parseFloat(formValues.no_of_bags_to);

        if (!isNaN(no_of_bags_from) && !isNaN(no_of_bags_to)) {
            const difference = no_of_bags_to - no_of_bags_from + 1;
            setFormValues(prevValues => ({
                ...prevValues,
                no_of_bags: difference.toFixed(0)
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                no_of_bags: ''
            }));
        }
    };

    const handleSubmit = async () => {
        const payload = {
            ...formValues
        };
        try {
            if (formMode === 'new') {
                // Create new invoice
                let res = await InvoiceDetailStore(payload);
                console.log("Invoice created:", res);
            } else if (formMode === 'edit') {
                // Update existing invoice
                let res = await invoiceDetailUpdate(payload);
                console.log("Invoice updated:", res);
            }
        } catch (err) {
            console.error("Error submitting invoice", err);
        }
    };

    return (
        <div className='invoice'>
            <div className="heading">
                <h1>Invoice Details</h1>
                {invoiceId && (<button className='invoice_detail_close' onClick={()=>setInvoice(false)}>Close</button>)}
            </div>
            <div className='container'>
                <form className="form" id="invoiceForm">
                    <fieldset>
                        <label className="input-box" htmlFor="invoice_no">Invoice Number:
                            <input type="text" id="invoice_no" name="invoice_no" value={formValues.invoice_no} onChange={handleChange} readOnly={formMode === 'view'} />
                        </label>
                        <label className="input-box" htmlFor="invoice_date">Date:
                            <input type="date" id="invoice_date" name="invoice_date" value={formValues.invoice_date} onChange={handleChange} readOnly={formMode === 'view'} />
                        </label>
                        <label className="input-box" htmlFor="billed_to">Bill To:
                            <select name="billed_to" value={formValues.billed_to} onChange={handleChange} disabled={formMode === 'view'}>
                                {masterData?.data?.customers_data.map((value, index) => (
                                    <option key={index} value={value?.customer_id}>{value?.cust_name}</option>
                                ))}
                            </select>
                        </label>
                        <label className="input-box" htmlFor="shipped_to">Ship To:
                            <select name="shipped_to" value={formValues.shipped_to} onChange={handleChange} disabled={formMode === 'view'}>
                                {masterData?.data?.customers_data.map((value, index) => (
                                    <option key={index} value={value?.customer_id}>{value?.cust_name}</option>
                                ))}
                            </select>
                        </label>
                        <label className="input-box" htmlFor="product_id">Product Name:
                            <select name="product_id" value={formValues.product_id} onChange={handleChange} disabled={formMode === 'view'}>
                                {masterData?.data?.products_data.map((value, index) => (
                                    <option key={index} value={value?.product_id}>{value?.product_name}</option>
                                ))}
                            </select>
                        </label>
                        <label className="input-box" htmlFor="hsn_id">HSN Code:
                            <select name="hsn_id" value={formValues.hsn_id} onChange={handleChange} disabled={formMode === 'view'}>
                                {masterData?.data?.hsn_data.map((value, index) => (
                                    <option key={index} value={value?.id}>{value?.hsn_no}</option>
                                ))}
                            </select>
                        </label>
                        <label className="input-box" htmlFor="initial_qty">Initial Qty of Goods in Mtrs:
                            <input type="text" id="initial_qty" name="initial_qty" value={formValues.initial_qty} onChange={handleChange} readOnly={formMode === 'view'} />
                        </label>
                        <label className="input-box" htmlFor="three_percent">Three percentage:
                            <input type="text" id="three_percent" name="three_percent" value={formValues.three_percent} readOnly />
                        </label>
                        <label className="input-box" htmlFor="total_qty">Total Qty of Goods in Mtrs:
                            <input type="text" id="total_qty" name="total_qty" value={formValues.total_qty} readOnly />
                        </label>
                        <label className="input-box" htmlFor="rate">Rate:
                            <input type="text" id="rate" name="rate" value={formValues.rate} onChange={handleChange} readOnly={formMode === 'view'} />
                        </label>
                        <label className="input-box" htmlFor="no_of_bags_from">No of Bags/Bundles From:
                            <input type="text" id="no_of_bags_from" name="no_of_bags_from" value={formValues.no_of_bags_from} onChange={handleChange} readOnly={formMode === 'view'} />
                        </label>
                        <label className="input-box" htmlFor="no_of_bags_to">No of Bags/Bundles To:
                            <input type="text" id="no_of_bags_to" name="no_of_bags_to" value={formValues.no_of_bags_to} onChange={handleChange} readOnly={formMode === 'view'} />
                        </label>
                        <label className="input-box" htmlFor="no_of_bags">No of Bags/Bundles:
                            <input type="text" id="no_of_bags" name="no_of_bags" value={formValues.no_of_bags} readOnly />
                        </label>
                        <label className="input-box" htmlFor="total_amount">Total Amount:
                            <input type="text" id="total_amount" name="total_amount" value={formValues.total_amount} readOnly />
                        </label>
                        <label className="input-box" htmlFor="amount_before_tax">Total Amount Before Tax:
                            <input type="text" id="amount_before_tax" name="amount_before_tax" value={formValues.amount_before_tax} readOnly />
                        </label>
                        <label>
                            <input type="radio" id="cgst_amountsgst_amount" name="tax_type" value="cgst_amountsgst_amount" checked={formValues.tax_type === 'cgst_amountsgst_amount'} onChange={handleChange} disabled={formMode === 'view'} /> CGST & SGST
                        </label>
                        <label>
                            <input type="radio" id="igst_amount" name="tax_type" value="igst_amount" checked={formValues.tax_type === 'igst_amount'} onChange={handleChange} disabled={formMode === 'view'} /> IGST
                        </label>
                        <label className="input-box" htmlFor="cgst_amount">CGST:
                            <input type="text" id="cgst_amount" name="cgst_amount" value={formValues.cgst_amount} readOnly />
                        </label>
                        <label className="input-box" htmlFor="sgst_amount">SGST:
                            <input type="text" id="sgst_amount" name="sgst_amount" value={formValues.sgst_amount} readOnly />
                        </label>
                        <label className="input-box" htmlFor="igst_amount">IGST:
                            <input type="text" id="igst_amount" name="igst_amount" value={formValues.igst_amount} readOnly />
                        </label>
                        <label className="input-box" htmlFor="invoice_amount">Total Amount After Tax:
                            <input type="text" id="invoice_amount" name="invoice_amount" value={formValues.invoice_amount} readOnly />
                        </label>
                        {/* <label className="input-box" htmlFor="amountInWords">Amount in Words:
                            <input type="text" id="amountInWords" name="amountInWords" value={formValues.amountInWords} readOnly />
                        </label> */}
                        <div className='btn'>
                            {formMode !== 'view' && <button type="button" onClick={handleSubmit}>Submit</button>}
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};
