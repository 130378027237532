import { profileApi } from '../slices/Profile'
import { configureStore } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk';



const store = configureStore({
  reducer: {
    [profileApi.reducerPath]:profileApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      profileApi.middleware,
      thunk
    ),
  devTools: process.env.NODE_ENV !== 'production',
})
export default store