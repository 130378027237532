import React, { useEffect, useState } from 'react';
import './invoicebill.css';
import ThreeDot from '../../assets/icons/threedot.svg';
import Pdf from '../../assets/icons/pdf.svg';
import View from '../../assets/icons/view.svg';
import Download from '../../assets/icons/download.svg';
import Edit from '../../assets/icons/edit.svg';
import folder_icon from '../../assets/images/folder_icon.png'
import { useDownloadInvoiceQuery, useMasterDetailsQuery } from '../../slices/Profile';
import { InvoiceForm } from '../Modal/invoiceForm/InvoiceForm';

const InvoiceBill = () => {
    const { data: masterData, refetch: masterDataRefetch } = useMasterDetailsQuery();
    const [invoice, setInvoice] = useState(false)
    const [invoiceId, setInvoiceId] = useState('')
    const [invoiceType, setInvoiceType] = useState('')
    const [downloadType, setDownloadType] = useState(null);
    const { data: downloadFile, refetch } = useDownloadInvoiceQuery({ invoice_id: invoiceId, download_type: downloadType }, { skip: !invoiceId || !downloadType });
    console.log(downloadType, "downloadType");

    const [expandedYear, setExpandedYear] = useState(null); // Track which year's invoices are expanded
    const [showIcons, setShowIcons] = useState(null); // Track which card's icons are showing

    // Group invoices by year
    const invoicesByYear = masterData?.data?.invoice_entries.reduce((acc, invoice) => {
        const year = new Date(invoice.invoice_date).getFullYear();
        if (!acc[year]) {
            acc[year] = [];
        }
        acc[year].push(invoice);
        return acc;
    }, {});

    const toggleYear = (year) => {
        setExpandedYear(expandedYear === year ? null : year); // Toggle visibility of invoices for the selected year
    };

    const toggleIcons = (index) => {
        setShowIcons(showIcons === index ? null : index); // Show icons for clicked card only
    };


    const handleView = (invoice_id) => {
        setInvoice(true)
        setInvoiceId(invoice_id)
        setInvoiceType('view')
        console.log(`View invoice with ID: ${invoice_id}`);
    };

    const handleDownload = async (invoice_id, item, invoice_no) => {
        console.log('handleDownload triggered:', { invoice_id, item, invoice_no });
        setInvoiceId(invoice_id);
        setDownloadType(item);
        try {
            const file = await fetchDownloadFile(invoice_id, item);
            if (file) {
                const url = window.URL.createObjectURL(new Blob([file]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Invoice ${invoice_no}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('No file available for download.');
            }
        } catch (error) {
            console.error('Error fetching file:', error);
        }
    };

    const fetchDownloadFile = async (invoice_id, item) => {
        const currentToken = JSON.parse(localStorage.getItem('token'));
        const headers = new Headers();
        if (currentToken) {
            headers.set('Authorization', `Bearer ${currentToken}`);
        }
        headers.set('Content-Type', 'application/json');
        headers.set('ngrok-skip-browser-warning', 'true');

        try {
            const response = await fetch(`https://api.srisendhurtex.in/api/v1/download-invoice?invoice_id=${invoice_id}&download_type=${item}`, {
                method: 'GET',
                headers: headers,
            });
            if (response.ok) {
                return await response.arrayBuffer();
            } else {
                console.error('Failed to fetch file:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('Error fetching file:', error);
            return null;
        }
    };

    const handleEdit = (invoice_id) => {
        setInvoice(true)
        setInvoiceId(invoice_id)
        setInvoiceType('edit')
        console.log(`Edit invoice with ID: ${invoice_id}`);
    };

    return (
        <>
            {!invoice && (
                <div>
                    <div className='customer_heading'>
                        <h3>Invoice Bill</h3>
                    </div>
                    <div className='pdf_design'>
                        {masterData?.data?.invoice_entries.length > 0 ?
                            <>
                                {invoicesByYear && Object.keys(invoicesByYear).map((year) => (
                                    <div key={year} className='year_folder'>
                                        <div className='year_heading' onClick={() => toggleYear(year)}>
                                            <img className='folder_icon' src={folder_icon} alt='folder_icon' />
                                            <h4 className='folder_year'>{year}</h4>
                                        </div>
                                        {expandedYear === year && (
                                            <div className='invoices_list'>
                                                {invoicesByYear[year].map((value, index) => (
                                                    <div key={index} className='pdf_card'>
                                                        <div className='pdf_image'>
                                                            <img src={Pdf} alt='Pdf' />
                                                        </div>
                                                        <div className='pdf_each_card'>
                                                            <div>
                                                                <p>Invoice {value?.invoice_no}.pdf</p>
                                                                <p><span>{value?.invoice_date}</span></p>
                                                            </div>
                                                            <div onClick={() => toggleIcons(index)} className="three_dot_icon">
                                                                <img src={ThreeDot} alt='ThreeDot' />
                                                                {showIcons === index && (
                                                                    <div className='action_icons'>
                                                                        <div className='action_type' onClick={() => handleView(value?.invoice_id)}>
                                                                            <img src={View} alt='View' />View
                                                                        </div>
                                                                        <div className='action_type' onClick={() => handleEdit(value?.invoice_id)}>
                                                                            <img src={Edit} alt='Edit' />Edit
                                                                        </div>
                                                                        <div className='action_type' onClick={() => handleDownload(value?.invoice_id, 1, value?.invoice_no)}>
                                                                            <img src={Download} alt='Download' />Download Original
                                                                        </div>
                                                                        <div className='action_type' onClick={() => handleDownload(value?.invoice_id, 2, value?.invoice_no)}>
                                                                            <img src={Download} alt='Download' />Download Duplicate
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                            :
                            ' No data avaliable'
                        }
                    </div>

                </div>
            )}
            {invoice && (
                <InvoiceForm invoiceId={invoiceId} invoiceType={invoiceType} setInvoice={setInvoice} />
            )}
        </>
    );
};

export default InvoiceBill;
