import React, { useState, useEffect } from 'react';
import './CustomerModal.css';
import { useCustomerDetailsStoreMutation, useCustomerDetailsUpdateMutation } from '../../../slices/Profile';

const CustomerModal = ({ setAddCustomerModal, editCustomerData, refetchMasterData }) => {
    const [formData, setFormData] = useState({
        cust_name: '',
        cust_address: '',
        gst_no: ''
    });
    const [addCustomer] = useCustomerDetailsStoreMutation();
    const [updateCustomer] = useCustomerDetailsUpdateMutation();

    useEffect(() => {
        if (editCustomerData) {
            setFormData({
                cust_name: editCustomerData?.cust_name || '',
                cust_address: editCustomerData?.cust_address || '',
                gst_no: editCustomerData?.gst_no || ''
            });
        }
    }, [editCustomerData]);

    const handleSubmit = async () => {
        try {
            if (editCustomerData) {
                await updateCustomer({
                    cust_id: editCustomerData.customer_id,
                    ...formData
                });
            } else {
                await addCustomer(formData);
            }
            refetchMasterData(); 
            setAddCustomerModal(false); 
        } catch (error) {
            console.error("Error during mutation:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <div className='Customer_modal'>
            <div>
                <div className='add_customer_modal'>
                    <h3>{editCustomerData ? 'Edit' : 'Add'} Customer</h3>
                    <button onClick={() => setAddCustomerModal(false)}>x</button>
                </div>
                <div>
                    <form className="forms" onSubmit={(e) => e.preventDefault()}>
                        <label className="input-boxs">Name
                            <input
                                type='text'
                                name='cust_name'
                                value={formData.cust_name}
                                onChange={handleChange}
                            />
                        </label>
                        <label className="input-boxs">Address
                            <textarea
                                name='cust_address'
                                value={formData.cust_address}
                                onChange={handleChange}
                            ></textarea>
                        </label>
                        <label className="input-boxs">Gstin
                            <input
                                type='text'
                                name='gst_no'
                                value={formData.gst_no}
                                onChange={handleChange}
                            />
                        </label>
                        <div className='add_btn'>
                            <button type="button" onClick={handleSubmit}>
                                {editCustomerData ? 'Update' : 'Add'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CustomerModal;
